import React from 'react';
import theme from './disparadores.theme.module.scss';
import Image from '../image/image';
import type Disparador from '../../models/season';
import Navigation from '../navigation/navigation';
import MuyHumanosFooter from '../footer/footer';
import type ContactInfo from '../../models/contactInfo';
import Loader from '../loader/loader';
import {thematicTriggerIsBefore} from '../../consts';
import {useNavigate} from 'react-router-dom';
import Logo from '../logo/logo';

type DisparadoresProps = {
	disparadoresElements?: Disparador[];
	contactInfo: ContactInfo | undefined;
	isLoading?: boolean;
};

function Disparadores({disparadoresElements, contactInfo, isLoading}: DisparadoresProps) {
	const goTo = useNavigate();
	return (
		<>
			{isLoading && <Loader />}
			<div id='disparadores' className={`text-center ${theme.mainContainer}`}>
				<Logo size={350} classChildName={theme.giganticLogo} />
				<Navigation isForPage={true}/>
				<div className='container'>

					<div className={theme.titleRow}>
						<h2>Temporadas</h2>

					</div>
					<div className={theme.normalText}>
En cada temporada vas a encontrar charlas de expertos, testimonios de vida inspiradores y demás contenido
que haría tan bien que el mundo conozca.
						<br/>
						<br/>
Si sabés componer, te invitamos a ayudarnos a difundir estos mensajes a través de tu arte ;)
						<br/>
						<br/>
					</div>

					<div className={theme.wrap}>
						{disparadoresElements === undefined
							? 'loading'
							: disparadoresElements.sort(thematicTriggerIsBefore).map((disparadoresElement, _) => (
								<div key={disparadoresElement.title} className={theme.rowItem} onClick={() => {
									goTo(`/disparador?id=${disparadoresElement.id}`);
								}}>

									<Image
										title={disparadoresElement.title}
										subTitle={disparadoresElement.subTitle}
										smallImage={disparadoresElement.coverImage}
										smallText={true}
										childClass={theme.hoverEffect}
									/>

								</div>
							))
						}
					</div>

				</div>

			</div>
			<MuyHumanosFooter contactInfo={contactInfo} secondaryColor={true}/>
		</>
	);
}

export default Disparadores;
