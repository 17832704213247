import React from 'react';
import theme from './navigation.theme.module.scss';
import Logo from '../logo/logo';
import {mobileBreakpoint} from '../../consts';

type NavigationProps = {
	isForPage?: boolean;

};

function Navigation({isForPage = false}: NavigationProps) {
	const isOnMobile = window.innerWidth < mobileBreakpoint;
	return (
		<nav id='menu' className={`navbar navbar-default navbar-fixed-top ${theme.mainNavContainer}`}>
			<div className={'container ' + theme.overlay}>
				<div className={'navbar-header ' + theme.myNavHeader }>
					<button
						type='button'
						className={`navbar-toggle collapsed ${theme.navbarToggle}`}
						data-toggle='collapse'
						data-target='#bs-example-navbar-collapse-1'
					>
						{' '}
						<span className='sr-only'>Toggle navigation</span>
						{' '}
						<span className={`icon-bar ${theme.iconBar}`} />
						{' '}
						<span className={`icon-bar ${theme.iconBar}`} />
						{' '}
						<span className={`icon-bar ${theme.iconBar}`} />
						{' '}
					</button>
					<a className={`navbar-brand page-scroll ${theme.mainHeaderTitle}`} href={isForPage ? '/#page-top' : '#page-top'}>
						<Logo />
						<div className={theme.logoText}>MUY&nbsp; HUMANOS</div>

					</a>
					{' '}
				</div>

				<div
					className='collapse navbar-collapse'
					id='bs-example-navbar-collapse-1'
				>
					<ul className={`nav navbar-nav navbar-right ${theme.navRow}`}>
						<li>
							<a
								href={isForPage ? '/#about' : '#about'}
								className='page-scroll'
								data-toggle={isOnMobile ? 'collapse' : null}
								data-target={isOnMobile ? '#bs-example-navbar-collapse-1' : null}
							>
                ¿Quiénes Somos?
							</a>
						</li>
						<li>
							<a
								href='/disparadores'
								className='page-scroll'
								data-toggle={isOnMobile ? 'collapse' : null}
								data-target={isOnMobile ? '#bs-example-navbar-collapse-1' : null}
							>
                Temporadas
							</a>
						</li>
						<li>
							<a
								href={isForPage ? '/#temporada' : '#temporada'}
								className='page-scroll'
								data-toggle={isOnMobile ? 'collapse' : null}
								data-target={isOnMobile ? '#bs-example-navbar-collapse-1' : null}
							>
                Última temporada
							</a>
						</li>
						<li>
							<div style={isOnMobile ? {
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'column',
								marginTop: '20px',
							} : {}}>
								<a
									href='/subir-canción'
									className= {'btn btn-custom btn-lg page-scroll'}
									data-toggle={isOnMobile ? 'collapse' : null}
									style={{padding: '13px 45px'}}
									data-target={isOnMobile ? '#bs-example-navbar-collapse-1' : null}
								>
                SUBIR CANCIÓN
								</a>
							</div>

						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
}

export default Navigation;
