import React from 'react';
import theme from './uploadSongPage.theme.module.scss';
import type ContactInfo from '../../models/contactInfo';
import Loader from '../loader/loader';
import Navigation from '../navigation/navigation';
import MuyHumanosFooter from '../footer/footer';
import UploadSongForm from '../uploadSongForm/uploadSongForm';
import type Disparador from '../../models/season';

type UploadSongPageProps = {
	isAppLoading: boolean;
	contactInfo: ContactInfo | undefined;
	disparadores?: Disparador[];
};

function UploadSongPage({isAppLoading, contactInfo, disparadores}: UploadSongPageProps) {
	return (
		<>
			{(isAppLoading) && <Loader />}
			<div className={theme.mainContainer}>
				<Navigation isForPage={true}/>

				<UploadSongForm disparadores={disparadores?.map(elem => elem.title) ?? ['Cargando...']} disparadorName={undefined} />

			</div>
			<MuyHumanosFooter contactInfo={contactInfo} secondaryColor={true}/>
		</>
	);
}

export default UploadSongPage;
