import React, {useState, useEffect} from 'react';
import theme from './header.theme.module.scss';
import Logo from '../logo/logo';
import {processText, replaceSpaces} from '../../consts';

type HeaderProps = {
	title?: string;
	paragraph?: string;
	subtitle?: string;
	imageUrl?: string;
	setIsLoading: (set: boolean) => void;
};

export default function Header({title, paragraph, subtitle, imageUrl, setIsLoading}: HeaderProps) {
	const [backgroundStyle, setBackgroundStyle] = useState({});

	useEffect(() => {
		if (imageUrl) {
			const img = new Image();
			img.src = imageUrl;
			img.onload = () => {
				// Image has loaded, apply the background style and call your function
				setBackgroundStyle({backgroundImage: `url(${imageUrl})`});
				onImageLoad(); // Call your function here
			};
		}
	}, [imageUrl]);

	// Your custom function to call after the image has loaded
	const onImageLoad = () => {
		setIsLoading(false);
		// Add any other actions you want to perform here
	};

	return (
		<header id='header' className={theme.mainContainer}>
			<div className={theme.intro} style={backgroundStyle}>
				<div className={theme.overlay}>
					<div className={theme.introText}>
						<div className={theme.expandContainer}>
							<div className={theme.transparentContainer}>
								<div className={theme.titleRow}>
									<Logo size={70}/>
									<h1 className={theme.megaText}>
										{ replaceSpaces(title) }

									</h1></div>
								<p>{processText(paragraph) }</p>
								<p className={theme.subtitle}>{subtitle ?? 'Loading'}</p>
							</div>
						</div>
						<a
							href='#about'
							className= {`btn btn-custom btn-lg page-scroll ${theme.btn}`}
						>
              QUIERO SABER MÁS
						</a>
						{' '}
					</div>
				</div>
			</div>
		</header>
	);
}

