import React from 'react';
import theme from './staticMhText.theme.module.scss';

type StaticMhTextProps = {
	white?: boolean;

};

function isIphone13() {
	const {userAgent} = navigator;

	// Check for iOS device
	const isios = userAgent.includes('iPhone') && /iOS|Apple/.test(userAgent);
	const iPhone13Resolution = window.devicePixelRatio === 3 && (window.screen.width === 390 || window.screen.height === 844);

	return isios && iPhone13Resolution;
}

function StaticMhText({white}: StaticMhTextProps) {
	const isOnIphone = isIphone13();

	return (
		<div className={theme.textContent} style={isOnIphone ? {height: '100vh'} : {}}>
			<div className={theme.gridItem}>
				<h3 style={white ? {color: 'white'} : {}}>1. INSPIRATE</h3>
				<div >En esta página vas a
encontrar disparadores
(contenido inspirador),
agrupados por
temporadas.</div>
			</div>
			<div className={theme.gridItem}>
				<h3 style={white ? {color: 'white'} : {}}>3. PRODUCCIÓN</h3>
				<div >Desde Muy Humanos iremos seleccionando y produciendo las canciones en calidad profesional bajo el respaldo técnico de Wannaclap.</div></div>
			<div className={theme.gridItem}>
				<h3 style={white ? {color: 'white'} : {}}>2. COMPONÉ</h3>
				<div >Te invitamos a componer
canciones que transmitan
esos mensajes y subirlas a
esta plataforma. Las
canciones que subas no
necesitan producción,
simplemente grabalas
con tu celular.</div>

			</div>
			<div className={theme.gridItem}>
				<h3 style={white ? {color: 'white'} : {}}>4. LANZAMIENTO</h3>
				<div >Esas producciones serán
lanzadas en Spotify y
demás medios, ipara que
se escuchen en todas
partes!</div>
			</div>

		</div>
	);
}

export default StaticMhText;
