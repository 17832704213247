import React from 'react';
import theme from './sponsors.theme.module.scss';
import {AnimationOnScroll} from 'react-animation-on-scroll';
import type Sponsor from '../../models/sponsor';
import {replaceSpaces} from '../../consts';

type SponsorsProps = {
	sponsors: Sponsor[] | undefined;
};

function Sponsors({sponsors}: SponsorsProps) {
	return (
		<div id='sponsors' className={`text-center ${theme.mainContainer}`}>
			<AnimationOnScroll
				animateOnce={true}
				animateIn='animate__fadeIn'
				duration={0.8}
				delay={200}
				className={theme.titleRow}
			>
				<h2>{replaceSpaces('Nos  apoyan')}</h2>
			</AnimationOnScroll>
			<AnimationOnScroll
				animateOnce={true}
				className={theme.wrap}
				style={{marginBottom: 10}}
				animateIn='animate__fadeIn'
				duration={0.8}
				delay={200}
			>
				{sponsors === undefined
					? 'loading'
					: sponsors.map(service => {
						if (service.isMain) {
							return (
								<div key={service.url}>
									<img
										src={service.url}
										className={theme.logoMainImage}
										alt='Sponsor Logo'
									/>
								</div>
							);
						}

						return null;
					})}
			</AnimationOnScroll>
			<AnimationOnScroll
				animateOnce={true}
				className={theme.wrap}
				animateIn='animate__fadeIn'
				duration={0.8}
				delay={200}
			>
				{sponsors === undefined
					? 'loading'
					: sponsors.map((service, index) => {
						if (!service.isMain) {
							return (
								<div key={service.url + index}>
									<img
										src={service.url}
										className={theme.logoImage}
										alt='Sponsor Logo'
									/>
								</div>
							);
						}

						return null;
					})}
			</AnimationOnScroll>
		</div>
	);
}

export default Sponsors;
